import ConfigError from './ConfigError';

export default (prop) => {
  const value = process.env[`VUE_APP_${prop}`];

  if (typeof value === 'undefined') {
    throw new ConfigError(`VUE_APP_${prop} does not exist in the config file`);
  }

  return value;
};
