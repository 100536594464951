<style lang="scss" scoped>
  @import "../style/common";

  .error {
    background: rgba(245, 245, 245, 1);
    padding: $lh*2 0 $lh*3 0;
    min-height: 100vh;

    &__container {
      @include make-container;
    }

    &__message {
      color: $red;
      font-size: $font-size-big;
      line-height: $base-line-height;
    }
  }

</style>

<template>
  <div class="error">
    <div class="error__container">
      <p class="error__message">
        Error!
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'error',
};
</script>
