import { firebase } from '../modules/firebase-client';

const VueFirebaseCRUD = {
  install(Vue) {
    Vue.mixin({
      beforeCreate() {
        this.$db = firebase.firestore();
      },
    });
  },
};

export default VueFirebaseCRUD;
