import Vue from 'vue';
import Router from 'vue-router';
import ErrorLayout from './layouts/Error.vue';

Vue.use(Router);

const defaults = {
  error: ErrorLayout,
  delay: 200,
  timeout: 3000,
};

export default new Router({
  routes: [
    {
      path: '/',
      name: 'map',
      component: () => ({
        component: import('./layouts/MapLayout.vue'),
      }),
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: () => ({
        component: import('./components/Dashboard.vue'),
        ...defaults,
      }),
    },
    {
      path: '/dashboard/companies/:perPage?',
      name: 'companies',
      component: () => ({
        component: import('./components/CompaniesManagement.vue'),
        ...defaults,
      }),
    },
    {
      path: '/dashboard/company-types',
      name: 'company-types',
      component: () => ({
        component: import('./components/CompanyTypeEditor.vue'),
        ...defaults,
      }),
    },
    {
      path: '/dashboard/edit-company/:companyId',
      name: 'edit-company',
      component: () => ({
        component: import('./layouts/SingleCompanyEdit.vue'),
        ...defaults,
      }),
    },
    {
      path: '/add-company/:step',
      name: 'add-company',
      component: () => ({
        component: import('./layouts/AddCompanyLayout.vue'),
        ...defaults,
      }),
    },
    {
      path: '/company/:id',
      name: 'company',
      component: () => ({
        component: import('./layouts/SingleCompany.vue'),
        ...defaults,
      }),
    },
    {
      path: '/error/',
      name: 'error',
      component: ErrorLayout,
    },
  ],
});
