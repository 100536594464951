import isFunction from 'lodash/isFunction';
import isObject from 'lodash/isObject';
import noop from 'lodash/noop';
import has from 'lodash/has';

export default {
  install(Vue) {
    Vue.mixin({
      beforeCreate() {
        if (!this.$options.db) {
          return;
        }

        const keys = Object.keys(this.$options.db);
        const computed = {};

        // For $options.$db property register a computed property prefixed with _
        keys.forEach((key) => {
          const settings = this.$options.db[key];
          const computedProp = `${key}Query`;

          if (isFunction(settings)) {
            computed[computedProp] = settings;
          }

          if (isObject(settings)) {
            if (has(settings, 'ref') && isFunction(settings.ref)) {
              computed[computedProp] = settings.ref;
            }
          }
        });

        // Merge dynamically created computed properties with component's computed properties
        this.$options.computed = {
          ...this.$options.computed,
          ...computed,
        };
      },
      created() {
        if (!this.$options.db) {
          return;
        }

        // Apply vuefire bindings to all entries
        Object.keys(this.$options.db)
          .forEach((key) => {
            const settings = this.$options.db[key];
            const callbacks = {
              before: isFunction(settings.before) ? settings.before : noop,
              resolve: isFunction(settings.resolve) ? settings.resolve : noop,
              reject: isFunction(settings.reject) ? settings.reject : noop,
            };

            const bind = (k, q) => {
              callbacks.before.call(this);

              // tempKey key used when settings.wait is `true`
              // It's a workaround for: https://github.com/vuejs/vuefire/issues/83
              const tempKey = `_temp_${k}`;

              if (settings.wait) {
                this[tempKey] = null;
              }

              const fieldToBindTo = settings.wait ? tempKey : k;

              this.$bind(fieldToBindTo, q)
                .then(() => {
                  if (settings.wait) {
                    this[k] = this[tempKey];
                    delete this[tempKey];
                  }
                  callbacks.resolve.call(this);
                })
                .catch(() => {
                  delete this[tempKey];
                  callbacks.reject.call(this);
                });
            };

            // Bind the collection when the component is created
            const query = this[`${key}Query`];
            bind(key, query);

            // Re-bind properties when computed properties re-evaluate
            this.$watch(`${key}Query`, (q) => {
              bind(key, q);
            });
          });
      },
    });
  },
};
