/* global GFTB_VOID_I18N */
import has from 'lodash/has';
import get from 'lodash/get';
import config from '../config';

export default (path) => {
  if (config('USE_TRANSLATIONS') !== 'true') {
    return path;
  }

  if (!has(GFTB_VOID_I18N, path)) {
    if (process.env.NODE_ENV !== 'production') {
      console.warn(`Translation for the "${path}" not found in GFTB_VOID_I18N`);
    }

    return path;
  }

  return get(GFTB_VOID_I18N, path);
};
