<style lang="scss" scoped>
  .site {
    border-bottom: none;
    padding: 0;
    width: 100%;
  }
</style>

<template>
  <div class="site">
    <keep-alive include="map-layout">
      <router-view>
      </router-view>
    </keep-alive>
  </div>
  <!-- /.site-header -->
</template>

<script>
export default {
  name: 'app',
};
</script>
