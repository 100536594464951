import i18n from '../modules/i18n';

const VueI18n = {
  install(Vue) {
    Vue.mixin({
      beforeCreate() {
        this.$i18n = key => i18n(key);
      },
    });
  },
};

export default VueI18n;
