import GoogleMapsLoader from 'google-maps';
import config from '../config';

GoogleMapsLoader.VERSION = '3.34';
GoogleMapsLoader.REGION = 'GB';
GoogleMapsLoader.LANGUAGE = 'en';
GoogleMapsLoader.LIBRARIES = [
  'geometry',
  'places',
];
GoogleMapsLoader.KEY = config('GOOGLE_MAPS_API_KEY');

export default GoogleMapsLoader.load;
