import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import config from '../config';

const app = firebase.initializeApp({
  apiKey: config('FIREBASE_API_KEY'),
  authDomain: config('FIREBASE_AUTH_DOMAIN'),
  databaseURL: config('FIREBASE_DATABASE_URL'),
  projectId: config('FIREBASE_PROJECT_ID'),
  storageBucket: config('FIREBASE_STORAGE_BUCKET'),
  messagingSenderId: config('FIREBASE_MESSAGING_SENDER_ID'),
});

const db = firebase.firestore(app);

export {
  firebase,
  db,
};
